import React, { useState, useEffect } from "react";
import "./globalStyles.scss";
import { Switch, Route } from "react-router-dom";
import AppHeader from "./components/AppHeader/AppHeader";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import Home from "./pages/Home/Home";
import Biog from "./pages/Biog/Biog";
import RecordingDetails from "./pages/RecordingDetails/RecordingDetails";
import Media from "./pages/Media/Media";
import Interviews from "./pages/Interviews/Interviews";
import Recordings from "./pages/Recordings/Recordings";
import Reviews from "./pages/Reviews/Reviews";
import Footer from "./components/Footer/Footer";
import Contact from "./pages/Contact/Contact";
import GalleryPage from "./pages/GalleryPage";
import News from "./pages/News";

const App = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const width = window.innerWidth;
    setIsMobile(width < 700 ? true : false);
    window.addEventListener("resize", () => {
      const width = window.innerWidth;
      setIsMobile(width < 700 ? true : false);
    });

    return window.removeEventListener("resize", () => {});
  }, [window.innerWidth]);

  return (
    <div className='app-container'>
      <div>
        <AppHeader
          drawerOpen={drawerOpen}
          isMobile={isMobile}
          toggleDrawer={() => setDrawerOpen(!drawerOpen)}
        />
        <SideDrawer
          close={() => setDrawerOpen(!drawerOpen)}
          isMobile={isMobile}
          open={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      </div>
      <div className='app-container__content'>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/media' component={Media} />
          <Route path='/interviews' component={Interviews} />
          <Route path='/reviews' component={Reviews} />
          <Route path='/news' component={News} />
          <Route path='/recordings' component={Recordings} />
          <Route path='/biog' component={Biog} />
          <Route path='/recording/:id' component={RecordingDetails} />
          <Route path='/contact' component={Contact} />
          <Route path='/galleries' component={GalleryPage} />
        </Switch>
        <Footer />
      </div>
    </div>
  );
};

export default App;
