import React, { useState, useEffect } from "react";
import { getCDs } from "../../contentful";
import { Link } from "react-router-dom";
import "./CDShowCaseStyles.scss";
import Diapason from "../../assets/diapason.png";
import Gramophone from "../../assets/gramophone.jpg";

const CDShowCase = ({ homepage }) => {
  const [cds, setCds] = useState([]);

  useEffect(() => {
    getCDs().then((data) => {
      setCds(data);
      console.log(data);
    });
  }, []);
  return (
    <div className='page-content'>
      <h3 className='title'>Discography</h3>

      <div className='cds-container'>
        {cds.map((cd) => (
          <div className='cds-container__cd'>
            {cd.fields.diapasonAward && !cd.fields.gramophoneAward && (
              <div className='cds-container__award'>
                <img src={Diapason} />
              </div>
            )}
            {cd.fields.gramophoneAward && (
              <div className='cds-container__award2'>
                <img src={Gramophone} />
              </div>
            )}
            {cd.fields.cover && (
              <Link to={`/recording/${cd.sys.id}`}>
                <img src={cd?.fields?.cover?.fields?.file?.url} />
              </Link>
            )}
          </div>
        ))}
      </div>
      {homepage && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Link
            style={{ textDecoration: "none" }}
            className='button'
            to='/news'
          >
            Discography >>
          </Link>
        </div>
      )}
    </div>
  );
};

export default CDShowCase;
