import React, { useState, useEffect } from "react";
import ReviewPanel from "../../components/ReviewPanel/ReviewPanel";
import { getReviews } from "../../contentful";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    getReviews().then((data) =>
      setReviews(data.filter((r) => !r.fields.cdReview))
    );
  }, []);
  return (
    <div className='page-content'>
      <h3 className='title'>Reviews</h3>
      {reviews.map((r) => (
        <ReviewPanel review={r.fields} />
      ))}
    </div>
  );
};

export default Reviews;
