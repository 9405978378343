import React, { useState, useEffect } from "react";
import { getNews } from "../contentful";
import NewsItem from "../components/NewsItem/NewsItem";
import NewsItemSmall from "../components/NewsItemSmall/NewsItemSmall";
import { Link } from "react-router-dom";

const News = ({ homepage }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    getNews().then((data) => setItems(data));
  }, []);

  return (
    <div className='page-content'>
      <h3 className='title'>News</h3>
      <div
        style={
          homepage
            ? { display: "flex", flexWrap: "wrap", justifyContent: "center" }
            : {}
        }
      >
        {items.map((item) => (
          <div className={homepage ? "news-item--home" : "news-item"}>
            {!homepage ? (
              <NewsItem item={item.fields} />
            ) : (
              <NewsItemSmall item={item.fields} />
            )}
          </div>
        ))}
      </div>
      {homepage && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Link
            style={{ textDecoration: "none" }}
            className='button'
            to='/news'
          >
            News Page >>{" "}
          </Link>
        </div>
      )}
    </div>
  );
};

export default News;
