import React from "react";
import "./NewsItemSmall.scss";
import marked from "marked";

const NewsItemSmall = ({ item }) => {
  const { title, subtitle, image } = item;
  return (
    <div className='news-item-small'>
      <div className='news-item-small__header'>
        {item.title && (
          <div className='news-item-small__title'>
            {" "}
            <h3>{item.title}</h3>
          </div>
        )}
        {item.subtitle && (
          <div className='news-item-small__title'>
            {" "}
            <p dangerouslySetInnerHTML={{ __html: subtitle }} />
          </div>
        )}
      </div>

      {item.image && (
        <div className='news-item-small__image'>
          <img src={item?.image?.fields?.file?.url} />
        </div>
      )}
    </div>
  );
};

export default NewsItemSmall;
