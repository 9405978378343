export const ROUTES = [
  { label: "home", route: "/" },
  { label: "biography", route: "/biog" },
  { label: "discography", route: "/recordings" },
  { label: "media", route: "/media" },
  { label: "reviews", route: "/reviews" },
  { label: "interviews", route: "/interviews" },
  { label: "galleries", route: "/galleries" },
  { label: "news", route: "/news" },
  { label: "contact", route: "/contact" },
];
