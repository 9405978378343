import React from "react";
import "./NewsItemStyles.scss";
import marked from "marked";

const NewsItem = ({ item }) => {
  return (
    <div className='news-item'>
      <div className='news-item__header'>
        {item.title && (
          <div className='news-item__title'>
            {" "}
            <h1>{item.title}</h1>
          </div>
        )}

        {item?.image?.fields?.file && (
          <img className='news-item__image' src={item.image.fields.file.url} />
        )}
      </div>
      {item.mainContent && (
        <section
          dangerouslySetInnerHTML={{ __html: marked(item.mainContent) }}
        />
      )}
    </div>
  );
};

export default NewsItem;
