import React from "react";
import "./AppHeaderStyles.scss";
import { Link } from "react-router-dom";

const AppHeader = ({ drawerOpen, toggleDrawer, isMobile }) => {
  return (
    <div className='app-header'>
      <Link to='/' className='app-header__link'>
        {" "}
        <h1>
          <span className='app-header__capital'>C</span>arole{" "}
          <span className='app-header__capital'>C</span>erasi
        </h1>
      </Link>
      {isMobile && (
        <button className='menu-button' onClick={toggleDrawer}>
          {!drawerOpen ? "MENU" : "CLOSE MENU"}
        </button>
      )}
    </div>
  );
};

export default AppHeader;
