import React from "react";
import CDShowCase from "../../components/CDShowCase/CDShowCase";

const Recordings = () => {
  return (
    <div>
      <CDShowCase />
    </div>
  );
};

export default Recordings;
