import React from "react";
import CDShowCase from "../../components/CDShowCase/CDShowCase";
import Hero from "../../components/Hero/Hero";
import Media from "../Media/Media";
import ReviewsTicker from "../../components/ReviewsTicker/ReviewsTicker";
import News from "../News";

const Home = () => {
  return (
    <div>
      <Hero />
      <div className='page-content homepage'>
        <ReviewsTicker />

        <News homepage />
        <div className='homepage__media'>
          <Media homepage />
        </div>
        <CDShowCase homepage />
      </div>
    </div>
  );
};

export default Home;
