import React, { useEffect, useState } from "react";
import { getVideos } from "../../contentful";
import marked from "marked";
import { Link } from "react-router-dom";

const Media = ({ homepage }) => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    getVideos().then((data) =>
      setVideos(homepage ? data.filter((v) => v.fields.homepage) : data)
    );
  }, []);

  const liveConcerts = videos.filter((v) => v.fields.liveConcert);
  return (
    <div className='page-content'>
      <h3 className='title'>Media</h3>
      <div className='videos-container'>
        {videos
          .filter((v) => !v.fields.liveConcert)
          .map((v) => (
            <article
              className={
                homepage
                  ? "videos-container__homepage-video"
                  : "videos-container__video"
              }
              dangerouslySetInnerHTML={{ __html: marked(v.fields.embedCode) }}
            />
          ))}
      </div>
      {!homepage && (
        <div>
          {liveConcerts.length > 0 && (
            <>
              <div className='divider' />
              <h3 style={{ textAlign: "center" }}>Live Concerts</h3>
            </>
          )}
          <div className='videos-container'>
            {liveConcerts.map((v) => (
              <article
                className='videos-container__video'
                dangerouslySetInnerHTML={{
                  __html: marked(v.fields.embedCode),
                }}
              />
            ))}
          </div>
        </div>
      )}
      {homepage && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Link
            style={{ textDecoration: "none" }}
            className='button'
            to='/news'
          >
            Media Page >>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Media;
