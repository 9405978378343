import React, { useState, useEffect } from "react";
import marked from "marked";
import { getContactPage } from "../../contentful";
import { Mail, Phone } from "@material-ui/icons";

const Contact = () => {
  const [content, setContent] = useState({});

  useEffect(() => {
    getContactPage().then((data) => setContent(data[0].fields));
  }, []);
  return (
    <div className='page-content'>
      <h3 className='title'>Contact</h3>
      {content.image && (
        <img
          className='contact-page__image'
          src={content.image.fields.file.url}
        />
      )}
      {content.text && (
        <section
          className='contact-page__text'
          dangerouslySetInnerHTML={{ __html: marked(content.text) }}
        />
      )}
      {content.tel && (
        <p className='contact-page__detail'>
          <Phone />
         <span> {content.tel}</span>
        </p>
      )}
      {content.email && (
        <p>
          <a className='contact-page__detail' href={`mailto:${content.email}`}>
            <Mail />
           <span> {content.email}</span>
          </a>
        </p>
      )}
    </div>
  );
};

export default Contact;
