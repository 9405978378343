const client = require("contentful").createClient({
  space: "2fixg2yr9h7f",
  accessToken: "0nzKpp9OAGhN7rR9t0cXnvD24cLKumoep2HIen1ovOM",
});

const getCDs = () =>
  client
    .getEntries({ content_type: "cd", limit: 500, order: "-fields.date" })
    .then((response) => response.items);

const getCD = (id) =>
  client
    .getEntries({ content_type: "cd", "sys.id": id, limit: 500 })
    .then((response) => response.items);

const getVideos = () =>
  client
    .getEntries({ content_type: "youTubeVideo" })
    .then((response) => response.items);

const getInterviews = () =>
  client
    .getEntries({ content_type: "interview", limit: 500 })
    .then((response) => response.items);

const getReviews = () =>
  client
    .getEntries({ content_type: "review", limit: 500 })
    .then((response) => response.items);

const getBiog = () =>
  client
    .getEntries({ content_type: "biography", limit: 500 })
    .then((response) => response.items);

const getContactPage = () =>
  client
    .getEntries({ content_type: "contactPage", limit: 500 })
    .then((response) => response.items);

const getNews = () =>
  client
    .getEntries({ content_type: "news", limit: 500 })
    .then((response) => response.items);

const getGalleryImages = () =>
  client
    .getEntries({ content_type: "galleryImage", limit: 500 })
    .then((response) => response.items);

export {
  getNews,
  getGalleryImages,
  getCDs,
  getCD,
  getContactPage,
  getVideos,
  getInterviews,
  getReviews,
  getBiog,
};
