import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
import "./GalleryStyles.scss";

const GalleryDisplay = ({ images }) => {
  const photoClasses = ["", "big", "tall", "wide"];

  return (
    <SRLWrapper>
      <div className='grid-wrapper'>
        {images.map((p) => {
          const randomIdx = Math.floor(Math.random() * 3);

          return (
            <div className={`${photoClasses[randomIdx]} image-container`}>
              {" "}
              <img
                src={p.fields.image.fields.file.url}
                alt={p.fields.image.fields.title}
              />
              {p.fields.image.fields.title && (
                <div className='image-container__description'>
                  {/* <span>{p.fields.image.fields.title}</span> */}
                  {p.fields.formal && (
                    <a
                      href={p.fields.image.fields.file.url}
                      download
                      target='_blank'
                    >
                      download high resolution
                    </a>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </SRLWrapper>
  );
};

export default GalleryDisplay;
