import React, { useState, useEffect, useRef } from "react";
import "./ReviewPanel.scss";
import marked from "marked";

const ReviewPanel = ({ review }) => {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(200);
  const [divHeight, setDivHeight] = useState(null);
  const ref = useRef();

  useEffect(() => {
    measureHeights();
  }, [open, ref]);

  const measureHeights = () => {
    setHeight(!open ? 230 : ref.current?.scrollHeight);
    setDivHeight(ref.current?.scrollHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", measureHeights);

    return () => window.removeEventListener("resize", measureHeights);
  }, []);

  return (
    <div ref={ref} className='review-panel' style={{ height }}>
      <p>{review?.title}</p>
      <p className='review-panel__source'>{review.source}</p>

      {review.body && (
        <p
          className='review-panel__quote'
          dangerouslySetInnerHTML={{ __html: marked(review.body) }}
        />
      )}
      {!review.body && (
        <p className='review-panel__quote'>{review.shortExtract}</p>
      )}
      {!open && divHeight > 300 && <div className='reviews-overlay' />}
      {divHeight > 300 && (
        <button
          className='button review-open-button'
          onClick={() => setOpen(!open)}
        >
          {open ? "close" : "read more"}
        </button>
      )}
    </div>
  );
};

export default ReviewPanel;
