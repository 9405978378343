import React, { useEffect } from "react";
import "./SideDrawerStyles.scss";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../constants";
import { YouTube } from "@material-ui/icons";
const SideDrawer = ({ open, close, setDrawerOpen, isMobile }) => {
  const handleScroll = () => setDrawerOpen(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const containerClasses = () => {
    if (isMobile && !open) return "side-drawer--mobile";
    if (isMobile && open) return "side-drawer--mobile open";
    if (!isMobile) return "side-drawer";
  };

  return (
    <div className={containerClasses()}>
      <div className='side-drawer__links'>
        {ROUTES.map((r) => (
          <NavLink
            exact
            onClick={close}
            activeClassName='active'
            className='side-drawer__link'
            to={r.route}
          >
            {r.label}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default SideDrawer;
