import React, { useState, useEffect } from "react";
import { getReviews } from "../../contentful";
import "./ReviewsTicker.scss";

const ReviewsTicker = () => {
  const [reviews, setReviews] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    getReviews().then((data) =>
      setReviews(data.filter((r) => r.fields.shortExtract))
    );
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      if (index < reviews.length - 1) {
        setIndex(index + 1);
      } else {
        setIndex(0);
      }
      console.log(index);
    }, 5000);

    return () => {
      clearInterval(id);
    };
  }, [reviews, index]);

  const Review = ({ quote, source }) => {
    useEffect(() => {}, [quote]);
    return (
      <div className='ticker'>
        <span className='ticker__quote'>{quote}</span>
        <span className='ticker__source'>{source}</span>
      </div>
    );
  };

  return (
    <Review
      quote={reviews[index]?.fields.shortExtract}
      source={reviews[index]?.fields.source}
    />
  );
};

export default ReviewsTicker;
