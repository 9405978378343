import React, { useState, useEffect } from "react";
import { getReviews, getCD } from "../../contentful";
import { useParams } from "react-router-dom";
import ReviewPanel from "../../components/ReviewPanel/ReviewPanel";
import Diapason from "../../assets/diapason.png";
import Gramophone from "../../assets/gramophone.jpg";
import marked from "marked";

const RecordingDetails = () => {
  const { id } = useParams();

  const [reviews, setReviews] = useState([]);
  const [recording, setRecording] = useState({});

  useEffect(() => {
    getCD(id).then((data) => {
      setRecording(data[0]);
    });
  }, [id]);

  useEffect(() => {
    if (id && recording?.sys) {
      getReviews().then((data) => {
        const relatedReviews = data.filter(
          (r) => r.fields.relatedRecording?.sys.id === recording?.sys.id
        );
        setReviews(relatedReviews);
      });
    }
  }, [recording]);

  return (
    <div className='page-content'>
      <h2 className='title'>{recording?.fields?.title}</h2>

      <div className='recording-details__header'>
        <div className='recording-details__cover'>
          <img src={recording?.fields?.cover.fields.file.url} />
        </div>

        <div className='recording-details__details'>
          <div className='recording-details__meta'>
            {recording?.fields?.diapasonAward && (
              <img className='recording-details__award' src={Diapason} />
            )}
            {recording?.fields?.gramophoneAward && (
              <img className='recording-details__award' src={Gramophone} />
            )}
            {recording?.fields?.spotifyLink && (
              <a
                className='button--light'
                href={recording?.fields?.spotifyLink}
                target='_blank'
              >
                Listen on Spotify
              </a>
            )}
          </div>
          {recording?.fields?.labelDetails && (
            <section
              dangerouslySetInnerHTML={{
                __html: marked(recording?.fields?.labelDetails),
              }}
            />
          )}

          {recording?.fields?.instrumentsText && (
            <section
              dangerouslySetInnerHTML={{
                __html: marked(recording?.fields?.instrumentsText),
              }}
            />
          )}
          {recording?.fields?.awardsText && (
            <section
              dangerouslySetInnerHTML={{
                __html: marked(recording?.fields?.awardsText),
              }}
            />
          )}
        </div>
      </div>

      <div className='recording-details__videos'>
        {recording?.fields?.youtubeVideo?.map((v, i) => {
          if (v?.fields?.embedCode)
            return (
              <div
                className='recording-details__videos__video'
                key={i}
                dangerouslySetInnerHTML={{
                  __html: marked(v?.fields?.embedCode),
                }}
              />
            );
          return null;
        })}
      </div>

      <div className='divider' />
      {reviews.length > 0 && <h3>Reviews</h3>}
      {reviews?.map((r, i) => (
        <ReviewPanel key={i} review={r?.fields} />
      ))}
    </div>
  );
};

export default RecordingDetails;
