import React, { useState, useEffect } from "react";
import ReviewsTicker from "../../components/ReviewsTicker/ReviewsTicker";
import { getBiog } from "../../contentful";
import marked from "marked";

const Biog = () => {
  const [biog, setBiog] = useState({});
  const [showShort, setShowShort] = useState(false);

  useEffect(() => {
    getBiog().then((data) => setBiog(data[0].fields));
  }, []);
  return (
    <div className='page-content'>
      <ReviewsTicker />
      <div className='biog-page'>
        <div className='biog-page__image'>
          {biog.image && <img src={biog?.image.fields.file.url} />}
        </div>
        <div className='biog-page__text'>
          <button className='button' onClick={() => setShowShort(!showShort)}>
            {showShort ? "View full biography" : "View short biography"}
          </button>
          {!showShort ? (
            <section
              dangerouslySetInnerHTML={{
                __html: marked(biog?.longVersion || ""),
              }}
            />
          ) : (
            <section
              dangerouslySetInnerHTML={{
                __html: marked(biog?.shortVersion || ""),
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Biog;
