import React, { useState, useEffect } from "react";
import { getInterviews } from "../../contentful";
import marked from "marked";

const Interviews = () => {
  const [interviews, setInterviews] = useState([]);

  useEffect(() => {
    getInterviews().then((data) => setInterviews(data));
  }, []);
  return (
    <div className='page-content'>
      {interviews.map((i) => (
        <div className='interview'>
          {i.fields.linkedRecording && (
            <img
              className='interview__cd-cover'
              src={i.fields.linkedRecording.fields.cover.fields.file.url}
            />
          )}
          <h2>{i.fields.publication}</h2>

          {i.fields.subtitle && <h5>{i.fields.subtitle}</h5>}
          <section
            dangerouslySetInnerHTML={{ __html: marked(i.fields.body) }}
          />
          <div className='divider' />
        </div>
      ))}
    </div>
  );
};

export default Interviews;
