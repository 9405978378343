import React from "react";
import "./HeroStyles.scss";
import carole from "../../assets/carole2.jpg";

const Hero = () => {
  return (
    <div className='hero'>
      <img src={carole} />
    </div>
  );
};

export default Hero;
